import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { getToken } from './api/token';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const isAuthenticated = () => {
  return getToken();
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated()
      ? <Component {...props} />
      : <Redirect to="/" />
  )} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

// Pages
const Login = React.lazy(() => import('./modules/Auth/Login'));
const ForgetPassword = React.lazy(() => import('./modules/Auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./modules/Auth/ResetPassword'));
const AccountActivation = React.lazy(() => import('./modules/Auth/AccountActivation'));
const Page404 = React.lazy(() => import('./common/Page404'));
const Page500 = React.lazy(() => import('./common/Page500'));

class App extends Component {
  
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <UnauthenticatedRoute exact path="/login" name="Login" component={Login} />
              <UnauthenticatedRoute exact path="/forget-password" name="ForgetPassword" component={ForgetPassword} />
              <UnauthenticatedRoute exact path="/reset-password/:token" name="ResetPassword" component={ResetPassword} />
              <UnauthenticatedRoute exact path="/account-activation/:token/email/:email" name="AccountActivation" component={AccountActivation} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <AuthenticatedRoute path="/" name="Home" component={Layout} />
            </Switch>
          </React.Suspense>
          <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} lightBackground/>
      </HashRouter>
    );
  }
}

export default App;
