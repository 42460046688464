// return user information
export const getUserInfo = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return false;
}

// return user information
export const getUser = () => {
  var info = getUserInfo();
  if (info !== false) {
    return info.email;
  }
  return false;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || false;
}

export const isGuest = () => {
  return ( getToken() === false );
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

// set the token and user to the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}